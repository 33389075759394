import { CheckType, GateDtoCheckType } from "../_Services/DataModel";

export function convertToCheckType(gateCheckType: GateDtoCheckType): CheckType | undefined {
    switch (gateCheckType) {
        case GateDtoCheckType.Check:
            return CheckType.Check;
        case GateDtoCheckType.In:
        case GateDtoCheckType.InOut:
        case GateDtoCheckType.AutoInOut:
            return CheckType.In;
        case GateDtoCheckType.Out:
            return CheckType.Out;
        case GateDtoCheckType.Auto:
            return CheckType.Auto;
        default:
            return undefined;
    }
}

export type CheckTypeOption = {
    value: CheckType;
    title: string;
};

export function getCheckTypeOptions(gateCheckType: GateDtoCheckType): CheckTypeOption[] {
    const result: CheckTypeOption[] = [];
    if (gateCheckType === GateDtoCheckType.Check) {
        result.push({ value: CheckType.Check, title: "Check" });
    }
    if (gateCheckType === GateDtoCheckType.In
        || gateCheckType === GateDtoCheckType.InOut
        || gateCheckType === GateDtoCheckType.AutoInOut
    ) {
        result.push({ value: CheckType.In, title: "In" });
    }
    if (gateCheckType === GateDtoCheckType.Out
        || gateCheckType === GateDtoCheckType.InOut
        || gateCheckType === GateDtoCheckType.AutoInOut
    ) {
        result.push({ value: CheckType.Out, title: "Out" });
    }
    if (gateCheckType === GateDtoCheckType.Auto
        || gateCheckType === GateDtoCheckType.AutoInOut
    ) {
        result.push({ value: CheckType.Auto, title: "Auto" });
    }

    return result;
}
