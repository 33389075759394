import * as React from "react";
import { ApiService } from "../_Services/ApiService";
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import { TextField } from "../_Components/TextField";
import { HeartbeatService } from "../_Services/HeartbeatService";
import { Layout, LayoutHeader, LayoutContent } from "../_Components/Layout";
import { AppRouter } from "../AppRouter";
import { SettingsService } from "../_Services/SettingsService";
import { autoinject } from "aurelia-dependency-injection";
import { r } from "../_Services/LanguageService";
import { AppState } from "../AppState";
import { once } from "../_Common/once";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

function normalizeUrl(url: string) {
    if (!url) {
        url = "";
    } else {
        url = url.trim();
    }

    if (url.endsWith("/") === false) {
        url = url + "/";
    }
    return url;
}

@autoinject()
export class InstallationPageModel {

    constructor(
        public apiService: ApiService,
        private heartbeatService: HeartbeatService,
        public appState: AppState,
        private appRouter: AppRouter,
        private settingsService: SettingsService) {
    }

    @observable inProgress: boolean = false;
    @observable isOk: boolean = false;
    @observable url: string = "";
    @observable currentInstallationToken: string = "";
    @observable installationToken: string = "";
    @observable deviceName: string = "";
    @observable message: string = "";

    @computed get isValid() {
        return this.installationToken && this.installationToken.length > 5 && this.deviceName;
    }

    @action
    setUrl(value: string) {
        this.url = value;
    }

    @action
    setInstallationToken(value: string) {
        this.installationToken = value;
    }

    @action
    setDeviceName(value: string) {
        this.deviceName = value;
    }

    @action
    load() {
        this.url = this.settingsService.apiRoot || this.apiService.defaultApiRoot;
        this.deviceName = this.settingsService.deviceName || "";
        this.currentInstallationToken = this.settingsService.installationToken || "";
        this.installationToken = "";
    }

    @action.bound
    async registerDevice() {
        if (this.url.length === 0 || this.installationToken.length === 0 || this.deviceName.length === 0) {
            alert("URL should point to API and include Installation Token and Device Name");
            return;
        }
        this.inProgress = true;
        const apiUrl = normalizeUrl(this.url);
        this.apiService.apiRoot = apiUrl;
        const response = await this.apiService.install(this.currentInstallationToken, this.installationToken, this.settingsService.deviceHardwareId, this.deviceName);

        this.isOk = response.IsOk;
        this.message = response.Message;

        if (response.IsOk === true) {
            setTimeout(() => {
                this.setup(response.ApiUrl || apiUrl, response.ApiToken, response.DeviceId);
                this.heartbeatService.downloadConfiguration().then(() => {
                    this.inProgress = false;
                    this.appRouter.Settings.navigator({}).navigate();
                });
                this.inProgress = false;
            }, 2000);
        } else {
            this.inProgress = false;
            alert(response.Message);
        }
    }

    @action.bound goBack() {
        this.appRouter.navigateHome();
    }

    private setup(apiUrl: string, secret: string, deviceId: string) {
        this.settingsService.apiRoot = apiUrl;
        this.settingsService.apiSecret = secret;
        this.settingsService.deviceId = deviceId;
        this.settingsService.deviceName = this.deviceName;
        this.settingsService.installationToken = this.installationToken;
    }

    @computed get hasInstallation(): boolean {
        return !!this.settingsService.apiSecret;
    }
}

export interface InstallationPageProps {
    model: InstallationPageModel;
}

@once((props: InstallationPageProps) => {
    props.model.load();
})
@observer
export class InstallationPage extends React.Component<InstallationPageProps> {
    render() {
        const { model } = this.props;
        return (
            <Layout>
                <LayoutHeader title={r("Device Registration")} goBack={model.goBack} isBackDisabled={!model.hasInstallation} />
                <LayoutContent>
                    {
                        !model.inProgress && <div className="simple-form">
                            {
                                model.apiService.hasApiMapSelection === false && <TextField
                                    title={r("URL for server API")}
                                    type="url"
                                    getValue={() => model.url}
                                    setValue={v => model.setUrl(v)}
                                />
                            }
                            {
                                model.apiService.hasApiMapSelection && <RadioGroup
                                    value={model.url}
                                    onChange={(e: any) => model.setUrl(e.target.value)}
                                >
                                    {
                                        model.apiService.apiMap.map(i =>
                                            <FormControlLabel key={i.url} value={i.url} control={<Radio />} label={i.name} />
                                        )
                                    }
                                </RadioGroup>
                            }
                            <TextField
                                title={r("Installation Token")}
                                getValue={() => model.installationToken}
                                setValue={v => model.setInstallationToken(v)}
                            />
                            <TextField
                                title={r("Device Name")}
                                getValue={() => model.deviceName}
                                setValue={v => model.setDeviceName(v)}
                            />
                            <div className="installation-actions">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={model.registerDevice}
                                    disabled={!model.isValid}
                                >
                                    {r("Register Device")}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={model.goBack}
                                    disabled={!model.hasInstallation}
                                >
                                    {r("Back to Scanning Page")}
                                </Button>
                            </div>
                            <div className="version installation-version">{model.appState.version}</div>
                        </div>
                    }
                    {
                        model.inProgress && <div className="center-container">
                            <div className={`circle-loader ${model.isOk ? "load-complete" : ""}`}>
                                <div className="checkmark draw"></div>
                            </div>
                            <div>{model.message}</div>
                        </div>
                    }
                </LayoutContent>
            </Layout>
        );
    }
}
