import { autoinject } from "aurelia-dependency-injection";
import { ApiService } from "./ApiService";
import { AppDb, CheckData } from "./AppDb";
import { action, observable } from "mobx";
import { ICheckRequest, ICheckResponse, IBadgesPackage, ICheckOfflineRequest, DeclineReason, BadgeState, IBadgeData, IDeclineMessage } from "./DataModel";
import { SettingsService } from "./SettingsService";

@autoinject()
export class OfflineDataService {
    constructor(
        private apiService: ApiService,
        private appDb: AppDb,
        private settingsService: SettingsService) {
    }

    private checksSyncIntervalHandle: number = null;
    private badgesSyncIntervalHandle: number = null;

    @observable offlineBadgesPackages: IBadgesPackage[] = [];
    @observable photosTotalRequired: number = 0;
    @observable photosTotalStored: number = 0;
    @observable forceBadgesDownloadInProgess: boolean = false;
    @observable photosDownloadInProgess: boolean = false;

    @action async downloadNextVersion(gateId: string, forceUpdate: boolean = false): Promise<number | null> {
        if (forceUpdate) {
            if (this.forceBadgesDownloadInProgess) {
                return null;
            }

            this.forceBadgesDownloadInProgess = true;
        }

        try {
            const lastBadgesPackage = this.offlineBadgesPackages
                .filter(i => i.GateId === gateId)
                .sort((a, b) => b.Version - a.Version)[0];

            const fromVersion = !lastBadgesPackage 
                ? 1 
                : lastBadgesPackage.Version + 1;

            const nextBadgesPackage = await this.apiService.getOfflineBadgesPackage(gateId, fromVersion);
            if (nextBadgesPackage) {
                await this.appDb.addOfflineBadgesPackage(nextBadgesPackage);
                this.offlineBadgesPackages.push(nextBadgesPackage);
                await this.refreshInternalOfflineData();
                if (this.settingsService.automaticPhotoDownload) {
                    setTimeout(async () => await this.downloadOfflinePhotos());
                }
            }
            return nextBadgesPackage?.Badges?.length || 0;
        } catch (e) {
            console.error("Next version downloading failed:", e);
            return null;
        } finally {
            this.forceBadgesDownloadInProgess = false;
        }
    }

    @action async deleteOfflineBadgesPackages(gateId: string): Promise<void> {
        await this.appDb.deleteOfflineBadgesPackages(gateId);
        this.offlineBadgesPackages = this.offlineBadgesPackages.filter(pkg => pkg.GateId !== gateId);
        await this.refreshInternalOfflineData();
    }

    @action async clearHistory(clearSyncedOnly: boolean = true): Promise<void> {
        await this.appDb.clearHistory(clearSyncedOnly);
    }

    @action async clearAllPhotos(): Promise<void> {
        await this.appDb.clearAllPhotos();
        this.photosTotalStored = 0;
    }

    @action async downloadOfflinePhotos(): Promise<void> {
        if (this.photosDownloadInProgess) {
            return;
        }

        try {
            this.photosDownloadInProgess = true;
            const photoIds = await this.appDb.getRequiredPhotoIds();
            const missingPhotoIds = await this.appDb.removeObsoleteAndGetMissingIds(photoIds);
            if (missingPhotoIds?.length) {
                for (const p of missingPhotoIds) {
                    let pd: Blob;
                    try {
                        pd = await this.apiService.getBadgePhoto(p);
                    } catch (e) {
                        continue;
                    }
                    await this.appDb.storePhoto(p, pd);
                    this.photosTotalStored = (await this.appDb.getStoredPhotoKeys()).length;
                }
            }
        } finally {
            this.photosDownloadInProgess = false;
        }
    }

    @action async initialize() {
        this.fetchInitialOfflineData();
        this.startCheckSyncer();
        this.startBadgesSyncer();
    }

    @action private startCheckSyncer() {
        this.stopCheckSyncer();
        this.offlineBadgesChecksSyncer();

        this.checksSyncIntervalHandle = setInterval(() => this.offlineBadgesChecksSyncer(), this.settingsService.offlineChecksSyncInterval);
    }

    @action private stopCheckSyncer() {
        if (this.checksSyncIntervalHandle !== null) {
            clearInterval(this.checksSyncIntervalHandle);
            this.checksSyncIntervalHandle = null;
        }
    }

    @action private startBadgesSyncer() {
        this.stopBadgesSyncer();
        this.offlineGatesBadgesSyncer();

        this.badgesSyncIntervalHandle = setInterval(() => this.offlineGatesBadgesSyncer(), this.settingsService.offlineBadgesSyncInterval);
    }

    @action private stopBadgesSyncer() {
        if (this.badgesSyncIntervalHandle !== null) {
            clearInterval(this.badgesSyncIntervalHandle);
            this.badgesSyncIntervalHandle = null;
        }
    }

    @action private async offlineBadgesChecksSyncer() {
        if (this.apiService.canSend) {
            const pending = await this.appDb.getPendingItems();
            const pendingByGate: { [id: string]: CheckData[]; } = pending.reduce((r, a) => {
                r[a.GateId] = r[a.GateId] || [];
                r[a.GateId].push(a);
                return r;
            }, {});

            for (const gateId in pendingByGate) {
                const apiObj: ICheckOfflineRequest[] = pendingByGate[gateId].map<ICheckOfflineRequest>(i => {
                    return {
                        TickId: i.TickId,
                        BadgeId: i.BadgeId,
                        CheckType: i.CheckType,
                        DeviceId: this.settingsService.deviceId,
                        GateId: i.GateId,
                        GeneratedDeclineReason: i.GeneratedDeclineReason,
                        IsValid: i.IsValid,
                        DateTime: i.Timestamp,
                        InvalidMessage: i.Message
                    };
                });
        
                try {
                    const responseOk = await this.apiService.checkSync(apiObj);
                    if (!responseOk) {
                        throw "Negative response from server";
                    }
                    this.appDb.markSynchronized(pending);
                } catch (e) {
                    console.error("Check sync failed:", e);
                }
            }
        }
    }

    @action private async offlineGatesBadgesSyncer() {
        if (this.apiService.canSend && this.settingsService.pullNewBadgesOnSync) {
            const enabledGates = this.settingsService.gates
                .filter(gate => this.offlineBadgesPackages.some(i => i.GateId === gate.Id));

            enabledGates.forEach(async gate => {
                try {
                    await this.downloadNextVersion(gate.Id);
                } catch (e) {
                    console.error(`Badges sync for gate ${gate.Id} failed:`, e);
                }
            });
        }
    }

    @action private async fetchInitialOfflineData() {
        this.offlineBadgesPackages = await this.appDb.getOfflineBadgesPackages();
        this.refreshInternalOfflineData();
    }

    @action private async refreshInternalOfflineData(): Promise<void> {
        const photoIds = await this.appDb.getRequiredPhotoIds();
        const missingPhotoIds = await this.appDb.removeObsoleteAndGetMissingIds(photoIds);

        this.photosTotalRequired = photoIds.length;
        this.photosTotalStored = photoIds.length - missingPhotoIds.length;
    }

    async checkBadgeOffline(request: ICheckRequest): Promise<ICheckResponse> {
        const badge = await this.appDb.getBadge(request.GateId, request.BadgeId);
        if (!badge) {
            return {
                IsValid: false,
                GeneratedDeclineReason: DeclineReason.BadgeDoesNotExist
            };
        }

        const declineReason = this.generateDeclineReson(badge);

        let image = null;
        try {
            image = await this.getPhotoBase64(badge.PhotoId);
        } catch (e) { }

        return {
            IsValid: declineReason == null,
            GeneratedDeclineReason: declineReason?.DeclineReason,
            OperationActionForDeclinationReason: declineReason?.OperationActionForDeclinationReason,
            Message: declineReason?.Message,
            Titles: badge.Titles,
            Images: image ? [image] : null
        };

    }

    private async getPhotoBase64(photoId: string): Promise<string> {
        return await this.appDb.getPhotoBase64(photoId);
    }

    private generateDeclineReson(badge: IBadgeData): IDeclineMessage {
        if (badge.State !== BadgeState.Active) {
            return { DeclineReason: DeclineReason.BadgeDeactivated };
        }

        if (!badge.IsValid) {
            return {
                DeclineReason: DeclineReason.MissingValidity,
                OperationActionForDeclinationReason: badge.OperationActionForDeclinationReason,
                Message: badge.InvalidMessage
            };
        }

        const currentTime = new Date();
        if (!badge.ValidityIntervals.length ||
            (
                badge.ValidityIntervals.some(i => i.From < currentTime && i.To > currentTime && i.IsValid)
                && !badge.ValidityIntervals.some(i => i.From < currentTime && i.To > currentTime && !i.IsValid))
        ) {
            return null;
        } else {
            return { DeclineReason: DeclineReason.NotValidDate };
        }
    }
}
