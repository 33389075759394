import { autoinject } from "aurelia-dependency-injection";
import { AppDb } from "./AppDb";
import { action } from "mobx";
import { TimeService } from "./TimeService";
import { SettingsService } from "./SettingsService";

const storageKey = "lastResetTimestamp";
const resetInterval = 5 * 60 * 1000; // 5 minutes in milliseconds
const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

@autoinject
export class DailyResetService {
  constructor(private appDb: AppDb, 
    private settingsService: SettingsService, 
    private timeService: TimeService) {
  }

  private intervalHandle: number = null;

  @action async start() {
    this.stop();
    await this.reset();
    this.intervalHandle = setInterval(async () => {
      await this.reset();
    }, resetInterval);
  }

  @action stop() {
    if (this.intervalHandle !== null) {
      clearInterval(this.intervalHandle);
      this.intervalHandle = null;
    }
  }

  @action async reset(): Promise<void> {
    try {
      const lastResetTimestamp = localStorage.getItem(storageKey);
      const currentTimestamp = this.timeService.getTimeIsoString().split("T")[0];

      if (!lastResetTimestamp || this.isMoreThanOneDayApart(currentTimestamp, lastResetTimestamp)) {
        if (this.settingsService.isHistoryAutoCleanUp) {
          await this.appDb.clearOutOfDateHistory();

          localStorage.setItem(storageKey, currentTimestamp);
        }
      }
    } catch (error) {
      console.error('Error resetting:', error);
    }
  }

  private isMoreThanOneDayApart(timestamp1: string, timestamp2: string): boolean {
    const date1 = new Date(timestamp1).getTime();
    const date2 = new Date(timestamp2).getTime();
  
    return Math.abs(date1 - date2) >= oneDayInMilliseconds;
  }
}