// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//
//           THIS FILE IS GENERATED DO NOT MODIFY IT MANUALLY
//           to regenerate this file run `dotnet run --generate-ts-model /path/to/generated.file model
//
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!



// *******************************************
//      Model
// *******************************************

// tslint:disable: indent

/** Base class for our model entries. */
export class ModelEntry {
	constructor(private path: string = null) {
	}

	private getFullName(name: string) {
		if (this.path !== null) {
			return `${this.path}.${name}`;
		}
		return name;
	}

	protected field(name: string): string {
		return this.getFullName(name);
	}

	protected entry<T extends ModelEntry>(name: string, type: { new(path: string): T; }): T {
		return new type(this.getFullName(name));
	}
}


class DeviceModel extends ModelEntry {
	$entity = "Device";

	Name = this.field("Name");


	Code = this.field("Code");


	IsActive = this.field("IsActive");


	LastActivity = this.field("LastActivity");


	DeviceTime = this.field("DeviceTime");


	SelectedGateId = this.field("SelectedGateId");


	SelectedCheckType = this.field("SelectedCheckType");


	EventId = this.field("EventId");


	Event = this.field("Event");

	private _Event: EventModel = null;
	get $Event(): EventModel {
		if (this._Event === null) { this._Event = this.entry("Event", EventModel); }
		return this._Event;
	}


	ApiToken = this.field("ApiToken");


	CreateDate = this.field("CreateDate");


	UpdatedDate = this.field("UpdatedDate");


	CreatedBy = this.field("CreatedBy");


	UpdatedBy = this.field("UpdatedBy");


	CreatedById = this.field("CreatedById");


	UpdatedById = this.field("UpdatedById");


	ImportKey = this.field("ImportKey");


	IsNew = this.field("IsNew");


	Id = this.field("Id");


}

class DeviceInstallationModel extends ModelEntry {
	$entity = "DeviceInstallation";

	InstallationToken = this.field("InstallationToken");


	Status = this.field("Status");


	ActivationDate = this.field("ActivationDate");


	EventId = this.field("EventId");


	Event = this.field("Event");

	private _Event: EventModel = null;
	get $Event(): EventModel {
		if (this._Event === null) { this._Event = this.entry("Event", EventModel); }
		return this._Event;
	}


	DeviceId = this.field("DeviceId");


	Device = this.field("Device");

	private _Device: DeviceModel = null;
	get $Device(): DeviceModel {
		if (this._Device === null) { this._Device = this.entry("Device", DeviceModel); }
		return this._Device;
	}


	CreateDate = this.field("CreateDate");


	UpdatedDate = this.field("UpdatedDate");


	CreatedBy = this.field("CreatedBy");


	UpdatedBy = this.field("UpdatedBy");


	CreatedById = this.field("CreatedById");


	UpdatedById = this.field("UpdatedById");


	ImportKey = this.field("ImportKey");


	IsNew = this.field("IsNew");


	Id = this.field("Id");


}

class EntityModel extends ModelEntry {
	$entity = "Entity";

	CreateDate = this.field("CreateDate");


	UpdatedDate = this.field("UpdatedDate");


	CreatedBy = this.field("CreatedBy");


	UpdatedBy = this.field("UpdatedBy");


	CreatedById = this.field("CreatedById");


	UpdatedById = this.field("UpdatedById");


	ImportKey = this.field("ImportKey");


	IsNew = this.field("IsNew");


	Id = this.field("Id");


}

class EventModel extends ModelEntry {
	$entity = "Event";

	Name = this.field("Name");


	IsActive = this.field("IsActive");


	LogoContent = this.field("LogoContent");


	Version = this.field("Version");


	ProjectId = this.field("ProjectId");


	Project = this.field("Project");

	private _Project: ProjectModel = null;
	get $Project(): ProjectModel {
		if (this._Project === null) { this._Project = this.entry("Project", ProjectModel); }
		return this._Project;
	}


	Installations = this.field("Installations");

	private _Installations: DeviceInstallationModel = null;
	get $Installations(): DeviceInstallationModel {
		if (this._Installations === null) { this._Installations = this.entry("Installations", DeviceInstallationModel); }
		return this._Installations;
	}


	Devices = this.field("Devices");

	private _Devices: DeviceModel = null;
	get $Devices(): DeviceModel {
		if (this._Devices === null) { this._Devices = this.entry("Devices", DeviceModel); }
		return this._Devices;
	}


	Gates = this.field("Gates");

	private _Gates: GateModel = null;
	get $Gates(): GateModel {
		if (this._Gates === null) { this._Gates = this.entry("Gates", GateModel); }
		return this._Gates;
	}


	GateCount = this.field("GateCount");


	InstallationCount = this.field("InstallationCount");


	DeviceCount = this.field("DeviceCount");


	CreateDate = this.field("CreateDate");


	UpdatedDate = this.field("UpdatedDate");


	CreatedBy = this.field("CreatedBy");


	UpdatedBy = this.field("UpdatedBy");


	CreatedById = this.field("CreatedById");


	UpdatedById = this.field("UpdatedById");


	ImportKey = this.field("ImportKey");


	IsNew = this.field("IsNew");


	Id = this.field("Id");


}

class GateModel extends ModelEntry {
	$entity = "Gate";

	Name = this.field("Name");


	CheckType = this.field("CheckType");


	Group = this.field("Group");


	IsActive = this.field("IsActive");


	EventId = this.field("EventId");


	Event = this.field("Event");

	private _Event: EventModel = null;
	get $Event(): EventModel {
		if (this._Event === null) { this._Event = this.entry("Event", EventModel); }
		return this._Event;
	}


	CreateDate = this.field("CreateDate");


	UpdatedDate = this.field("UpdatedDate");


	CreatedBy = this.field("CreatedBy");


	UpdatedBy = this.field("UpdatedBy");


	CreatedById = this.field("CreatedById");


	UpdatedById = this.field("UpdatedById");


	ImportKey = this.field("ImportKey");


	IsNew = this.field("IsNew");


	Id = this.field("Id");


}

class GateCheckModel extends ModelEntry {
	$entity = "GateCheck";

	GateId = this.field("GateId");


	Gate = this.field("Gate");

	private _Gate: GateModel = null;
	get $Gate(): GateModel {
		if (this._Gate === null) { this._Gate = this.entry("Gate", GateModel); }
		return this._Gate;
	}


	DeviceId = this.field("DeviceId");


	Device = this.field("Device");

	private _Device: DeviceModel = null;
	get $Device(): DeviceModel {
		if (this._Device === null) { this._Device = this.entry("Device", DeviceModel); }
		return this._Device;
	}


	BadgeId = this.field("BadgeId");


	CheckType = this.field("CheckType");


	IsValid = this.field("IsValid");


	MessageKey = this.field("MessageKey");


	Message = this.field("Message");


	IsRequestSuccessful = this.field("IsRequestSuccessful");


	RequestException = this.field("RequestException");


	TitlesJson = this.field("TitlesJson");


	Titles = this.field("Titles");


	CreateDate = this.field("CreateDate");


	UpdatedDate = this.field("UpdatedDate");


	CreatedBy = this.field("CreatedBy");


	UpdatedBy = this.field("UpdatedBy");


	CreatedById = this.field("CreatedById");


	UpdatedById = this.field("UpdatedById");


	ImportKey = this.field("ImportKey");


	IsNew = this.field("IsNew");


	Id = this.field("Id");


}

class ProjectModel extends ModelEntry {
	$entity = "Project";

	Name = this.field("Name");


	Url = this.field("Url");


	ApiKey = this.field("ApiKey");


	Events = this.field("Events");

	private _Events: EventModel = null;
	get $Events(): EventModel {
		if (this._Events === null) { this._Events = this.entry("Events", EventModel); }
		return this._Events;
	}


	CreateDate = this.field("CreateDate");


	UpdatedDate = this.field("UpdatedDate");


	CreatedBy = this.field("CreatedBy");


	UpdatedBy = this.field("UpdatedBy");


	CreatedById = this.field("CreatedById");


	UpdatedById = this.field("UpdatedById");


	ImportKey = this.field("ImportKey");


	IsNew = this.field("IsNew");


	Id = this.field("Id");


}

class UserModel extends ModelEntry {
	$entity = "User";

	IdentityId = this.field("IdentityId");


	UserName = this.field("UserName");


	FirstName = this.field("FirstName");


	LastName = this.field("LastName");


	ApiToken = this.field("ApiToken");


	IsAdmin = this.field("IsAdmin");


	Password = this.field("Password");


	NormalizedUserName = this.field("NormalizedUserName");


	Email = this.field("Email");


	NormalizedEmail = this.field("NormalizedEmail");


	IsEmailConfirmed = this.field("IsEmailConfirmed");


	PhoneNumber = this.field("PhoneNumber");


	IsPhoneNumberConfirmed = this.field("IsPhoneNumberConfirmed");


	PasswordHash = this.field("PasswordHash");


	SecurityStamp = this.field("SecurityStamp");


	AccessFailedCount = this.field("AccessFailedCount");


	IsLockoutEnabled = this.field("IsLockoutEnabled");


	LockoutEndDate = this.field("LockoutEndDate");


	CreateDate = this.field("CreateDate");


	UpdatedDate = this.field("UpdatedDate");


	CreatedBy = this.field("CreatedBy");


	UpdatedBy = this.field("UpdatedBy");


	CreatedById = this.field("CreatedById");


	UpdatedById = this.field("UpdatedById");


	ImportKey = this.field("ImportKey");


	IsNew = this.field("IsNew");


	Id = this.field("Id");


}

class CheckRequestModel extends ModelEntry {
	$entity = "CheckRequest";

	DeviceId = this.field("DeviceId");


	GateId = this.field("GateId");


	BadgeId = this.field("BadgeId");


	CheckType = this.field("CheckType");


	IsTestOnly = this.field("IsTestOnly");


	HighResImage = this.field("HighResImage");


}

class CheckResponseModel extends ModelEntry {
	$entity = "CheckResponse";

	IsValid = this.field("IsValid");


	Message = this.field("Message");


	Images = this.field("Images");


	Titles = this.field("Titles");


}

class DeviceConfigurationModel extends ModelEntry {
	$entity = "DeviceConfiguration";

	Version = this.field("Version");


	Titles = this.field("Titles");


	Images = this.field("Images");


	Gates = this.field("Gates");


}

class DeviceStatusRequestModel extends ModelEntry {
	$entity = "DeviceStatusRequest";

	Time = this.field("Time");


	GateId = this.field("GateId");


	CheckType = this.field("CheckType");


}

class DeviceStatusResponseModel extends ModelEntry {
	$entity = "DeviceStatusResponse";

	ConfigurationVersion = this.field("ConfigurationVersion");


}

class InstallationRequestModel extends ModelEntry {
	$entity = "InstallationRequest";

	InstallationToken = this.field("InstallationToken");


	DeviceName = this.field("DeviceName");


	DeviceCode = this.field("DeviceCode");


}

class InstallationResponseModel extends ModelEntry {
	$entity = "InstallationResponse";

	IsOk = this.field("IsOk");


	Message = this.field("Message");


	ApiUrl = this.field("ApiUrl");


	ApiToken = this.field("ApiToken");


	DeviceId = this.field("DeviceId");


}

class GateDtoModel extends ModelEntry {
	$entity = "GateDto";

	Id = this.field("Id");


	Name = this.field("Name");


	CheckType = this.field("CheckType");


}

class IGrantsHolderModel extends ModelEntry {
	$entity = "IGrantsHolder";

	Grants = this.field("Grants");


}


class DataModel {

	private _Device: DeviceModel = null;
	get Device(): DeviceModel {
		if (this._Device === null) { this._Device = new DeviceModel(); }
		return this._Device;
	}

	private _DeviceInstallation: DeviceInstallationModel = null;
	get DeviceInstallation(): DeviceInstallationModel {
		if (this._DeviceInstallation === null) { this._DeviceInstallation = new DeviceInstallationModel(); }
		return this._DeviceInstallation;
	}

	private _Entity: EntityModel = null;
	get Entity(): EntityModel {
		if (this._Entity === null) { this._Entity = new EntityModel(); }
		return this._Entity;
	}

	private _Event: EventModel = null;
	get Event(): EventModel {
		if (this._Event === null) { this._Event = new EventModel(); }
		return this._Event;
	}

	private _Gate: GateModel = null;
	get Gate(): GateModel {
		if (this._Gate === null) { this._Gate = new GateModel(); }
		return this._Gate;
	}

	private _GateCheck: GateCheckModel = null;
	get GateCheck(): GateCheckModel {
		if (this._GateCheck === null) { this._GateCheck = new GateCheckModel(); }
		return this._GateCheck;
	}

	private _Project: ProjectModel = null;
	get Project(): ProjectModel {
		if (this._Project === null) { this._Project = new ProjectModel(); }
		return this._Project;
	}

	private _User: UserModel = null;
	get User(): UserModel {
		if (this._User === null) { this._User = new UserModel(); }
		return this._User;
	}

	private _CheckRequest: CheckRequestModel = null;
	get CheckRequest(): CheckRequestModel {
		if (this._CheckRequest === null) { this._CheckRequest = new CheckRequestModel(); }
		return this._CheckRequest;
	}

	private _CheckResponse: CheckResponseModel = null;
	get CheckResponse(): CheckResponseModel {
		if (this._CheckResponse === null) { this._CheckResponse = new CheckResponseModel(); }
		return this._CheckResponse;
	}

	private _DeviceConfiguration: DeviceConfigurationModel = null;
	get DeviceConfiguration(): DeviceConfigurationModel {
		if (this._DeviceConfiguration === null) { this._DeviceConfiguration = new DeviceConfigurationModel(); }
		return this._DeviceConfiguration;
	}

	private _DeviceStatusRequest: DeviceStatusRequestModel = null;
	get DeviceStatusRequest(): DeviceStatusRequestModel {
		if (this._DeviceStatusRequest === null) { this._DeviceStatusRequest = new DeviceStatusRequestModel(); }
		return this._DeviceStatusRequest;
	}

	private _DeviceStatusResponse: DeviceStatusResponseModel = null;
	get DeviceStatusResponse(): DeviceStatusResponseModel {
		if (this._DeviceStatusResponse === null) { this._DeviceStatusResponse = new DeviceStatusResponseModel(); }
		return this._DeviceStatusResponse;
	}

	private _InstallationRequest: InstallationRequestModel = null;
	get InstallationRequest(): InstallationRequestModel {
		if (this._InstallationRequest === null) { this._InstallationRequest = new InstallationRequestModel(); }
		return this._InstallationRequest;
	}

	private _InstallationResponse: InstallationResponseModel = null;
	get InstallationResponse(): InstallationResponseModel {
		if (this._InstallationResponse === null) { this._InstallationResponse = new InstallationResponseModel(); }
		return this._InstallationResponse;
	}

	private _GateDto: GateDtoModel = null;
	get GateDto(): GateDtoModel {
		if (this._GateDto === null) { this._GateDto = new GateDtoModel(); }
		return this._GateDto;
	}

	private _IGrantsHolder: IGrantsHolderModel = null;
	get IGrantsHolder(): IGrantsHolderModel {
		if (this._IGrantsHolder === null) { this._IGrantsHolder = new IGrantsHolderModel(); }
		return this._IGrantsHolder;
	}

}


export const dataModel = new DataModel();


// *******************************************
//      Interfaces
// *******************************************

export interface IDevice {
	$id?: string;

	Name?: string;
	Code?: string;
	IsActive?: boolean;
	LastActivity?: any;
	DeviceTime?: any;
	SelectedGateId?: string;
	SelectedCheckType?: DeviceCheckType;
	EventId?: string;
	Event?: IEvent;
	ApiToken?: string;
	CreateDate?: any;
	UpdatedDate?: any;
	CreatedBy?: string;
	UpdatedBy?: string;
	CreatedById?: string;
	UpdatedById?: string;
	ImportKey?: string;
	IsNew?: boolean;
	Id?: string;
}

export interface IDeviceInstallation {
	$id?: string;

	InstallationToken?: string;
	Status?: DeviceInstallationStatus;
	ActivationDate?: any;
	EventId?: string;
	Event?: IEvent;
	DeviceId?: string;
	Device?: IDevice;
	CreateDate?: any;
	UpdatedDate?: any;
	CreatedBy?: string;
	UpdatedBy?: string;
	CreatedById?: string;
	UpdatedById?: string;
	ImportKey?: string;
	IsNew?: boolean;
	Id?: string;
}

export interface IEntity {
	$id?: string;

	CreateDate?: any;
	UpdatedDate?: any;
	CreatedBy?: string;
	UpdatedBy?: string;
	CreatedById?: string;
	UpdatedById?: string;
	ImportKey?: string;
	IsNew?: boolean;
	Id?: string;
}

export interface IEvent {
	$id?: string;

	Name?: string;
	IsActive?: boolean;
	LogoContent?: string;
	Version?: number;
	ProjectId?: string;
	Project?: IProject;
	Installations?: IDeviceInstallation[];
	Devices?: IDevice[];
	Gates?: IGate[];
	GateCount?: number;
	InstallationCount?: number;
	DeviceCount?: number;
	CreateDate?: any;
	UpdatedDate?: any;
	CreatedBy?: string;
	UpdatedBy?: string;
	CreatedById?: string;
	UpdatedById?: string;
	ImportKey?: string;
	IsNew?: boolean;
	Id?: string;
}

export interface IGate {
	$id?: string;

	Name?: string;
	CheckType?: GateCheckType;
	Group?: string;
	IsActive?: boolean;
	EventId?: string;
	Event?: IEvent;
	CreateDate?: any;
	UpdatedDate?: any;
	CreatedBy?: string;
	UpdatedBy?: string;
	CreatedById?: string;
	UpdatedById?: string;
	ImportKey?: string;
	IsNew?: boolean;
	Id?: string;
}

export interface IGateCheck {
	$id?: string;

	GateId?: string;
	Gate?: IGate;
	DeviceId?: string;
	Device?: IDevice;
	BadgeId?: string;
	CheckType?: DeviceCheckType;
	IsValid?: boolean;
	MessageKey?: string;
	Message?: string;
	IsRequestSuccessful?: boolean;
	RequestException?: string;
	TitlesJson?: string;
	Titles?: string[];
	CreateDate?: any;
	UpdatedDate?: any;
	CreatedBy?: string;
	UpdatedBy?: string;
	CreatedById?: string;
	UpdatedById?: string;
	ImportKey?: string;
	IsNew?: boolean;
	Id?: string;
}

export interface IProject {
	$id?: string;

	Name?: string;
	Url?: string;
	ApiKey?: string;
	Events?: IEvent[];
	CreateDate?: any;
	UpdatedDate?: any;
	CreatedBy?: string;
	UpdatedBy?: string;
	CreatedById?: string;
	UpdatedById?: string;
	ImportKey?: string;
	IsNew?: boolean;
	Id?: string;
}

export interface IUser {
	$id?: string;

	IdentityId?: string;
	UserName?: string;
	FirstName?: string;
	LastName?: string;
	ApiToken?: string;
	IsAdmin?: boolean;
	Password?: string;
	NormalizedUserName?: string;
	Email?: string;
	NormalizedEmail?: string;
	IsEmailConfirmed?: boolean;
	PhoneNumber?: string;
	IsPhoneNumberConfirmed?: boolean;
	PasswordHash?: string;
	SecurityStamp?: string;
	AccessFailedCount?: number;
	IsLockoutEnabled?: boolean;
	LockoutEndDate?: any;
	CreateDate?: any;
	UpdatedDate?: any;
	CreatedBy?: string;
	UpdatedBy?: string;
	CreatedById?: string;
	UpdatedById?: string;
	ImportKey?: string;
	IsNew?: boolean;
	Id?: string;
}


export interface IDeviceConfiguration {
	$id?: string;

	Version?: string;
	Titles?: string[];
	Images?: string[];
	Gates?: IGateDto[];
}

export interface IDeviceStatusRequest {
	$id?: string;

	Time?: any;
	GateId?: string;
	CheckType?: CheckType;
}

export interface IDeviceStatusResponse {
	$id?: string;

	ConfigurationVersion?: string;
	ServerTime?: Date;
}

export interface IInstallationRequest {
	$id?: string;

	CurrentInstallationToken?: string;
	InstallationToken?: string;
	DeviceName?: string;
	DeviceCode?: string;
}

export interface IInstallationResponse {
	$id?: string;

	IsOk?: boolean;
	Message?: string;
	ApiUrl?: string;
	ApiToken?: string;
	DeviceId?: string;
}

export interface IGateDto {
	$id?: string;

	Id?: string;
	Name?: string;
	CheckType?: GateDtoCheckType;
	IsOfflineModeSupported?: boolean;
}

export interface IIGrantsHolder {
	$id?: string;

	Grants?: any;
}


export enum DeviceCheckType {

	Check = 1,

	In = 2,

	Out = 3,

}

export enum DeviceInstallationStatus {

	New = 0,

	Used = 1,

	Canceled = 2,

	DeviceDeactivated = 3,

	Reserved = 4,

}

export enum GateCheckType {

	Check = 1,

	In = 2,

	Out = 4,

	InOut = 6,

}

export enum CheckType {

	Check = 1,

	In = 2,

	Out = 3,

	Auto = 4,

}

export enum GatePassDirection {
	Unknown = "Unknown",
	In = "In",
	Out = "Out",
	Auto = "Auto"
}

export enum ItemsType {

	Pending = 0,

	Synchronized = 1

}

export enum GateDtoCheckType {
	Check = "Check",
	In = "In",
	Out = "Out",
	InOut = "InOut",
	Auto = "Auto",
	AutoInOut = "AutoInOut",
}

export enum BadgeState {
	Active = "Active",
	Deactivated = "Deactivated",
	Deleted = "Deleted"
}

// ----------------------------

export interface ICheckDataBase {
	TickId: string;
	DeviceId?: string;
	GateId?: string;
	BadgeId?: string;
	CheckType?: CheckType;
	IsTestOnly?: boolean;
}
export interface ICheckOnlineRequest extends ICheckDataBase {
	HighResImage?: boolean;
}

export interface ICheckOfflineRequest extends ICheckDataBase {
	IsValid?: boolean;
	DateTime?: string;
	GeneratedDeclineReason?: DeclineReason;
	InvalidMessage?: string;
}

export interface ICheckRequest extends ICheckOnlineRequest, ICheckOfflineRequest {}

export interface ICheckResponse {
	IsValid?: boolean;
	Message?: string;
	OperationActionForDeclinationReason?: string;
	GatePassResponse?: GatePassDirection;
	GeneratedDeclineReason?: DeclineReason;
	Images?: string[];
	Titles?: string[];
}

export interface IDeclineMessage {
	DeclineReason?: DeclineReason;
	Message?: string;
	OperationActionForDeclinationReason?: string;
}

export enum DeclineReason {
	None = 0,
	BadgeDoesNotExist = 1,
	BadgeDeactivated = 2,
	NotCollected = 4,
	MoreValidBadges = 8,
	NotValidDate = 16,
	MissingVenue = 64,
	MissingZone = 256,
	MissingParam = 1024,
	OfflineGateMissing = 2048,
	WrongDirectionPersonAlreadyInside = 4096,
	WrongDirectionPersonNotInside = 8192,
	IncorrectCategory = 16384,
	AlreadyChecked = 32768,
	InvalidTimeRange = 262144,
	NumberOfPassesExceeded = 1048576,
	MissingValidity = 1073741824
}

export enum GateMode {
	Online = 0,
	Offline = 4,
}

// flag
export enum ErrorState {
	None = 0,
	TimeNotSynced = 1 << 1,
	DbQuotaReached = 1 << 2,
}

export interface IBadgesPackage {
	Id?: string;
	GateId?: string;
	GateName?: string;
    UpdateTime?: string;
    Version?: number;
    Badges?: IBadgeData[];
}

export interface IBadgeData {
	Id?: string;
	GateId?: string;
	Code?: string;
    State?: BadgeState;
    PhotoId?: string;
	InvalidMessage?: string;
	IsValid?: boolean;
    OperationActionForDeclinationReason?: string;
    Titles?: string[];
	ValidityIntervals?: IBadgeValidityInterval[];
}

export interface IBadgeValidityInterval {
	Id?: string;
	From?: Date;
	To?: Date;
	IsValid?: boolean;
}

export interface DbQuotaInfo {
	Quota: number;
	Usage: number;
	Units: "Bytes" | "KB" | "MB" | "GB";
	Percentage: number;
}

