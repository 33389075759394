import { MenuItem, MuiThemeProvider, Theme, Select as MuiSelect, InputLabel } from "@material-ui/core";
import createMuiTheme, { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { observer } from "mobx-react";
import React from "react";

export type Option = {
    title: React.ReactElement | string;
    value: string;
};

export interface SelectFieldProps {
    id?: string;
    title?: string;
    getValue: () => string;
    setValue: (value: string) => void;
    options: Option[];
    isLandscapeEnabled: boolean;
}

@observer
export class SelectField extends React.Component<SelectFieldProps> {
    getSelectMenuStyles(isLandscapeEnabled: boolean): React.CSSProperties {
        let menuStyle: React.CSSProperties = {};

        if (isLandscapeEnabled) {
            menuStyle = {
                height: "calc(100vw - 75px)",
                maxWidth: "95vh",
                WebkitOverflowScrolling: "touch",
            };
        }

        return menuStyle;
    }

    getCurrentSelectTheme(isLandscapeEnabled: boolean): Theme {
        const themeOptions: ThemeOptions = isLandscapeEnabled ? {
            typography: {
                useNextVariants: true,
            },
            overrides: {
                MuiPopover: {
                    paper: {
                        top: "140px !important",
                        left: "20px !important",
                    }
                },
            },
        } : {
            typography: {
                useNextVariants: true,
            }
        };
        return createMuiTheme(themeOptions);
    }

    render() {
        const { id="", title="", getValue, setValue, options, isLandscapeEnabled } = this.props;
        const value = getValue();
        const items = options
            .map((i) => <MenuItem key={i.value} value={i.value}>{i.title}</MenuItem>);

        return <MuiThemeProvider theme={this.getCurrentSelectTheme(isLandscapeEnabled)}>
            <InputLabel htmlFor={id}>{title}</InputLabel>
            <MuiSelect
                value={value}
                onChange={e => setValue(e.target.value)}
                inputProps={{
                    id: id,
                }}
                MenuProps={{
                    style: this.getSelectMenuStyles(isLandscapeEnabled),
                    PaperProps: {
                        style: {
                            transform: "translate3d(0, 0, 0)",
                            overflowX: "auto"
                        }
                    }
                }}
            >
                {items}
            </MuiSelect>
        </MuiThemeProvider>
    }
}