import React, { } from "react";
import { autoinject } from "aurelia-dependency-injection";
import { SettingsService } from "../_Services/SettingsService";
import { OfflineDataService } from "../_Services/OfflineDataService";
import { LayoutContent, Layout, LayoutHeader, LayoutFreezeScreen } from "../_Components/Layout";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import LinearProgress, { } from "@material-ui/core/LinearProgress";
import { DbQuotaInfo, IGateDto, IBadgesPackage } from "../_Services/DataModel";
import { observer } from "mobx-react";
import { observable, action, computed } from "mobx";
import { AppRouter } from "../AppRouter";
import { r } from "../_Services/LanguageService";
import { AppDb } from "../_Services/AppDb";
import { ApiService } from "../_Services/ApiService";
import { AppModel } from "../AppModel";
import DeleteIcon from "@material-ui/icons/Delete";
import EnterIcon from "@material-ui/icons/AssignmentTurnedIn";
import OfflineIcon from "@material-ui/icons/WifiOff";
import { AppState } from "../AppState";
import { confirmAction } from "../_Utils/confirmationUtils";
import { Dialog, DialogContent } from "@material-ui/core";
import { Table } from "../_Components/Table";
import { SelectField } from "../_Components/SelectField";
import { TimeService } from "../_Services/TimeService";
import { once } from "../_Common/once";

@autoinject()
export class OfflineModePageModel {
    constructor(
        public timeService: TimeService,
        public appRouter: AppRouter,
        public settingsService: SettingsService,
        private offlineDataService: OfflineDataService,
        private appDb: AppDb,
        private apiService: ApiService,
        private appState: AppState) {
    }

    @observable modalIsOpen: boolean = false;
    @observable showSuSettings: boolean = false;

    @observable gateId: string = "";
    @observable pass: string = "";

    @action async downloadNextPackageVersion(gateId: string) {
        await this.offlineDataService.downloadNextVersion(gateId, true);
    }

    @action async downloadOfflinePhotos() {
        await this.offlineDataService.downloadOfflinePhotos();
    }

    @action async deleteOfflineBadgesPackages(gateId: string) {
        await this.offlineDataService.deleteOfflineBadgesPackages(gateId);
    }

    @action async deleteStoredPhotos() {
        await this.offlineDataService.clearAllPhotos();
    }

    @action async clearCheckHistory(clearSyncedOnly: boolean) {
        await this.offlineDataService.clearHistory(clearSyncedOnly);
    }

    @action requestSu() {
        if (this.settingsService.requireSuPassword) {
            this.modalIsOpen = true;
        } else {
            this.showSuSettings = true;
        }
    }

    @action async suInputValueChanged(value: string) {
        if (await this.appState.checkSuPassword(value)) {
            this.modalIsOpen = false;
            this.showSuSettings = true;
        }
    }

    @action load() {
        this.showSuSettings = false;
        this.pass = "";
        this.gateId = "";
    }

    async getCheckHistoryCount(syndedOnly: boolean) {
        return await this.appDb.getCheckCount(syndedOnly);
    }

    getBadgesCount(gateId: string): number {
        const totalBadgesCount = this.offlineDataService.offlineBadgesPackages.reduce((acc, p) => {
            if (p.GateId === gateId) {
                return acc + p.Badges.length;
            }
            return acc;
        }, 0);
        
        return totalBadgesCount;
    }

    async deleteChecks(clearSyncedOnly: boolean) {
        const description = `${r("Are you sure?")} (${await this.getCheckHistoryCount(clearSyncedOnly)} ${r("to delete")})`;
        return confirmAction(async () => await this.clearCheckHistory(clearSyncedOnly), description);
    }

    @computed get dbQuotas(): DbQuotaInfo {
        return this.appDb.dbQuotas;
    }

    @computed get badgePhotosDownloaded(): number {
        return this.offlineDataService.photosTotalStored;
    }

    @computed get badgePhotosRequired(): number {
        return this.offlineDataService.photosTotalRequired;
    }

    @computed get canDownload(): boolean {
        return this.apiService.canSend;
    }

    @computed get lastOfflineBadgesPackages(): IBadgesPackage[] {
        const filteredPackages = this.offlineDataService.offlineBadgesPackages.reduce<{ [gateId: string]: IBadgesPackage }>((acc, pkg) => {
            if (!acc[pkg.GateId] || !acc[pkg.GateId].Version || (pkg.Version && acc[pkg.GateId].Version < pkg.Version)) {
                acc[pkg.GateId] = pkg;
            }
            return acc;
        }, {});

        return Object.keys(filteredPackages).map(key => filteredPackages[key]);
    }

    @computed get gatesWithoutPackages(): IGateDto[] {
        const offlinePackageGates = new Set(this.offlineDataService.offlineBadgesPackages.map(pkg => pkg.GateId));
        return this.settingsService.gates.filter(gate => gate.IsOfflineModeSupported && !offlinePackageGates.has(gate.Id));
    }

    @computed get ItemsCountToSynchronize(): number | null {
        return this.appDb.itemsToSynchronize;
    }

    @computed get ItemsCountSynchronized(): number | null {
        return this.appDb.itemsSynchronized;
    }

    @computed get ItemsCountTitle(): string {
        return `${this.ItemsCountSynchronized}/${this.ItemsCountTotal} (${this.ItemsCountToSynchronize}`;
    }

    @computed get ItemsCountTotal(): number {
        return this.ItemsCountSynchronized + this.ItemsCountToSynchronize;
    }

    @computed get badgesDownloadInProgress(): boolean {
        return this.offlineDataService.forceBadgesDownloadInProgess;
    }

    @computed get photosDownloadInProgress(): boolean {
        return this.offlineDataService.photosDownloadInProgess;
    }

    @computed get isLandscapeEnabled(): boolean {
        return this.settingsService.isLandscapeEnabled;
    }
}

export interface OfflineModePageProps {
    model: OfflineModePageModel;
    appModel: AppModel;
}

@once((props: OfflineModePageProps) => {
    props.model.load();
})
@observer
export class OfflineModePage extends React.Component<OfflineModePageProps> {
    render() {
        const { model } = this.props;

        return <Layout>
            <LayoutFreezeScreen freeze={[{isActive: model.badgesDownloadInProgress, text: "Downloading in progress..."}]} />
            <LayoutHeader title={r("Offline Mode")} goBack={() => { model.appRouter.Scan.navigator({}).navigate(); }} />
            <LayoutContent>
                <div className="simple-form form-controls">
                    <FormControl>
                        <FormLabel >
                            {r("Photos")}
                        </FormLabel>
                        <>{model.badgePhotosDownloaded}/{model.badgePhotosRequired}</>
                    </FormControl>
                    <FormControl>
                        <FormLabel >
                            {r("Gate Checks")}
                        </FormLabel>
                        <> {model.ItemsCountTitle} {r("to be uploaded")})</>
                    </FormControl>
                    {!!model.showSuSettings && <div className="offline-data-container">
                        <FormControl>
                            <FormLabel htmlFor="isPullOnSyncenabledBadge">{r("Auto-Pull Badges")}</FormLabel>
                            <Switch
                                name="isPullOnSyncenabledBadge"
                                checked={model.settingsService.pullNewBadgesOnSync}
                                onChange={e => model.settingsService.pullNewBadgesOnSync = e.target.checked}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="isPullOnSyncenabledPhoto">{r("Auto-Pull Photos")}</FormLabel>
                            <Switch
                                name="isPullOnSyncenabledPhoto"
                                checked={model.settingsService.automaticPhotoDownload}
                                onChange={e => model.settingsService.automaticPhotoDownload = e.target.checked}
                            />
                        </FormControl>
                        <FormControl>
                            <div className="initial-downloading-area">
                                <FormControl className="gate-selection">
                                    <SelectField
                                        id="gate"
                                        title={r("Gate")}
                                        getValue={() => model.gateId}
                                        setValue={(value) => { model.gateId = value; }}
                                        options={model.gatesWithoutPackages.map(i => ({ 
                                            title: i.Name, 
                                            value: i.Id 
                                        }))}
                                        isLandscapeEnabled={model.isLandscapeEnabled}
                                    />
                                </FormControl>
                                <FormControl className="action-area">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => model.downloadNextPackageVersion(model.gateId)}
                                    >
                                        {r("Init Download")}
                                    </Button>
                                </FormControl>
                            </div>
                        </FormControl>
                        <FormControl className="table-form-control">
                            <Table<IBadgesPackage>
                                data={model.lastOfflineBadgesPackages} 
                                columns={[
                                    {
                                        title: "",
                                        valueGetter: (i) => i.GateName || "",
                                        tableCellClassName: () => "no-wrap-item"
                                    },
                                    {
                                        title: "",
                                        valueGetter: (i) => {
                                            const version = i.Version ?? "0";
                                            const updateTime = i.UpdateTime && model.timeService.utcToLocal(i.UpdateTime);
                                            return `(v.${version}) ${updateTime}`;
                                        },
                                        tableCellClassName: () => "no-wrap-item"
                                    },
                                    {
                                        title: "",
                                        valueGetter: (i) => {
                                            const count = model.getBadgesCount(i.GateId);
                                            return count.toString();
                                        },
                                        tableCellClassName: () => "no-wrap-item"
                                    },
                                    {
                                        title: "",
                                        valueGetter: (i) => <div className="table-actions-column">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => model.downloadNextPackageVersion(i.GateId)}
                                            >
                                                {r("Download Next Version")}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => model.deleteOfflineBadgesPackages(i.GateId)}
                                            >
                                                {r("Delete Data")}
                                            </Button>
                                        </div>,
                                    }
                                ]} 
                            />
                        </FormControl>
                        <FormControl disabled={model.photosDownloadInProgress || (model.badgePhotosDownloaded === model.badgePhotosRequired)}>
                            <FormLabel htmlFor="downloadOfflinePhotos">
                                {(!model.canDownload) && <OfflineIcon className="blink-anim"></OfflineIcon>}
                            </FormLabel>
                            <Button className="offline-mode-button"
                                disabled={(!model.canDownload) || model.photosDownloadInProgress || (model.badgePhotosDownloaded === model.badgePhotosRequired)}
                                variant="contained"
                                color="primary"
                                name="downloadOfflinePhotos"
                                onClick={() => model.downloadOfflinePhotos()}>
                                {r("Download Offline Photos")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <Button
                                className="offline-mode-button"
                                variant="contained"
                                color="secondary"
                                name="deleteStoredPhotos"
                                onClick={() => confirmAction(async () => await model.deleteStoredPhotos())}>
                                {r("Delete Offline Photos")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <Button
                                className="offline-mode-button" variant="contained"
                                color="secondary"
                                name="deleteHistory"
                                onClick={() => model.deleteChecks(true)}>
                                {r("Delete Scanning History (only synced)")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <Button
                                className="offline-mode-button" variant="contained"
                                color="secondary"
                                name="deleteHistoryWithNotSynchronized"
                                onClick={() => model.deleteChecks(false)}
                            >
                                {r("Delete Scanning History (including non-synced)")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <FormLabel >
                                {`${r("Used Storage:")} ${model.dbQuotas.Percentage}% - ${model.dbQuotas.Usage}/${model.dbQuotas.Quota}(${model.dbQuotas.Units})`}
                            </FormLabel>
                            <LinearProgress color={model.dbQuotas.Percentage > 80 ? "secondary" : "primary"} variant="determinate" value={model.dbQuotas.Percentage} />
                        </FormControl>
                    </div>}
                    {!model.showSuSettings && <FormControl >
                        <Button variant="contained" color="primary"
                            name="modalOpen"
                            onClick={_ => model.requestSu()}
                        >
                            {r("Advanced Settings >>")}
                        </Button>
                    </FormControl>}
                    <Dialog
                        className="settings-pin-dialog"
                        open={model.modalIsOpen}
                        onClose={_ => model.modalIsOpen = false}
                        scroll="body"
                    >
                        <DialogContent>
                            <div className="pin-panel">
                                <h2 >{r("Enter Password")}</h2>
                                <input type="text" pattern="\d*"
                                    value={model.pass} className="text-security"
                                    onChange={e => {
                                        model.pass = e.target.value;
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === "Enter") {
                                            model.suInputValueChanged(model.pass);
                                        }
                                    }}
                                ></input>
                                <div className="number-dial">
                                    {Array.from(Array(10).keys()).map(i =>
                                        <Button
                                            key={`btn_${i}`}
                                            variant="contained"
                                            color="primary"
                                            onClick={_ => {
                                                model.pass = (`${model.pass}${i}`);
                                            }}
                                        >
                                            {i}
                                        </Button>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={_ => model.pass = ""}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={_ => model.suInputValueChanged(model.pass)}
                                    >
                                        <EnterIcon />
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </LayoutContent>
        </Layout>;
    }
}