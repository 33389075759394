import { autoinject } from "aurelia-dependency-injection";
import { observable, computed } from "mobx";
import { IInstallationResponse, IInstallationRequest, IDeviceStatusRequest, IDeviceStatusResponse, ICheckOnlineRequest, ICheckResponse, IDeviceConfiguration, ICheckOfflineRequest, IBadgesPackage } from "./DataModel";
import { OnlineService } from "./OnlineService";

const commonHeaders = {
    "Content-Type": "application/json",
    "Accept": "application/json"
};

export interface ApiMapItem {
    name: string;
    url: string;
}

@autoinject()
export class ApiService {
    constructor(private onlineService: OnlineService) {
        console.table((<any>window).apiMap);
    }

    defaultApiRoot = (<any>window).defaultApiRoot; // "http://localhost:5000/api-external/v1/";
    @observable apiMap: ApiMapItem[] = Array.isArray((<any>window).apiMap) ? (<any>window).apiMap : [];

    @observable apiRoot = this.defaultApiRoot;
    @observable deviceId: string = null;
    @observable apiSecret: string = null;
    onUnauthorize: () => Promise<any>;

    @computed get isInitialized(): boolean {
        return !!this.apiSecret && this.apiSecret !== ""
            && !!this.deviceId && this.deviceId !== "";
    }

    @computed get hasApiMapSelection(): boolean {
        return this.apiMap.length > 0;
    }

    @computed get canSend(): boolean {
        return this.isInitialized && this.onlineService.isOnline;
    }

    async install(installationToken: string, newInstallationToken: string, deviceCode: string, deviceName: string): Promise<IInstallationResponse> {
        const request: IInstallationRequest = {
            CurrentInstallationToken: installationToken,
            InstallationToken: newInstallationToken,
            DeviceCode: deviceCode,
            DeviceName: deviceName
        };

        return this.post<IInstallationResponse>(`device/install/${encodeURIComponent(newInstallationToken)}`, request);
    }

    async updateStatus(request: IDeviceStatusRequest): Promise<IDeviceStatusResponse> {
        return this.post<IDeviceStatusResponse>(`device/${encodeURIComponent(this.deviceId)}/status`, request);
    }

    async getDeviceConfiguration(): Promise<IDeviceConfiguration> {
        return this.get(`device/${this.deviceId}/configuration`);
    }

    async getOfflineBadgesPackage(gateId: string, fromVersion: number): Promise<IBadgesPackage> {
        const data = await this.get<IBadgesPackage>(`badge`, {
            gateId: gateId,
            fromVersion: fromVersion
        });

        return data;
    }
    async getBadgePhoto(photoId: string): Promise<Blob> {
        return this.getBlob(`badge/photo/${photoId}`);
    }

    async check(badge: ICheckOnlineRequest): Promise<ICheckResponse> {
        return this.post<ICheckResponse>(`gate/${badge.GateId}/check`, badge, { gateId: badge.GateId });
    }
    async checkSync(badges: ICheckOfflineRequest[]): Promise<boolean> {
        const gateIds = badges.map(i => i.GateId).filter((v, i, a) => a.indexOf(v) === i);
        if (gateIds.length !== 1) {
            throw new Error(`Gate id must be same for all badges. Ids ${gateIds}`);
        }
        return this.postNoReturn(`gate/${gateIds[0]}/tick-results`, badges, { gateId: gateIds[0], deviceId: this.deviceId });
    }

    async logError(timestamp: string, error: any) {
        if (!this.deviceId) {
            return Promise.resolve({});
        }

        const log = {
            Timestamp: timestamp,
            ErrorType: error.name,
            ErrorMessage: error.message,
            ErrorStack: error.stack,
        };
        return this.post<string>(`device/${encodeURIComponent(this.deviceId)}/log/error`, log);
    }

    async get<T>(url: string, queryParams: { [id: string]: string | number | boolean } = null): Promise<T> {
        const response = await this.action("GET", url, queryParams);
        if (!response.ok) {
            throw new Error("response invalid");
        }
        
        return response.json();
    }
    async getBlob(url: string, queryParams: { [id: string]: string | number | boolean } = null): Promise<Blob> {
        const response = await this.action("GET", url, queryParams);
        if (!response.ok) {
            throw new Error("response invalid");
        }
        return response.blob();
    }


    async postNoReturn(url: string, body: any, queryParams: { [id: string]: string | number | boolean } = null): Promise<boolean> {
        const r = await this.action("POST", url, queryParams, body);
        return r.ok && !r.redirected;
    }

    async post<T>(url: string, body: any, queryParams: { [id: string]: string | number | boolean } = null): Promise<T> {
        const r = await this.action("POST", url, queryParams, body);
        return r.json();
    }

    async action(action: string, url: string, queryParams: { [id: string]: string | number | boolean }, body: any = null): Promise<Response> {
        const headers = { "X-ApiKey": this.apiSecret, ...commonHeaders };
        const queryString = queryParams
            ? Object.keys(queryParams)
                .filter(i => queryParams[i] !== undefined)
                .reduce((a, v, i) => `${a}${i === 0 ? "?" : "&"}${encodeURIComponent(v)}=${encodeURIComponent(queryParams[v])}`, "")
            : "";
        const response = await fetch(`${this.apiRoot}${url}${queryString}`, {
            method: action,
            headers: headers,
            body: body !== null ? JSON.stringify(body) : null,
        });

        if (response.status === 401 && this.onUnauthorize) {
            await this.onUnauthorize();
            throw new Error("Unauthorize");
        }
        return await response;
    }

}