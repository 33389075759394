import { autoinject } from "aurelia-dependency-injection";
import { ApiService } from "./ApiService";
import { action } from "mobx";
import { SettingsService } from "./SettingsService";
import { TimeService } from "./TimeService";
import { ErrorState } from "./DataModel";
import { AppState } from "../AppState";
import { convertToCheckType } from "../_Utils/checkTypeEnumUtils";


@autoinject()
export class HeartbeatService {
    constructor(
        private apiService: ApiService,
        private settingsService: SettingsService,
        private timeService: TimeService,
        private appState: AppState) {
    }

    private intervalHandle: number = null;

    @action start() {
        this.stop();
        this.intervalHandle = setInterval(() => {
            if (this.apiService.canSend) {
                this.heartbeat();
            }
        }, this.settingsService.heartbeatInterval);
    }

    @action stop() {
        if (this.intervalHandle !== null) {
            clearInterval(this.intervalHandle);
            this.intervalHandle = null;
        }
    }

    @action async heartbeat() {
        if (!this.apiService.canSend) {
            return;
        }

        const timeBeforeRequest = new Date();
        const response = await this.apiService.updateStatus({
            Time: this.timeService.getTimeIsoString(),
            CheckType: this.settingsService.checkTypeId,
            GateId: this.settingsService.gateId,
        });
        const requestDuration = Date.now() - timeBeforeRequest.getTime();

        if (response.ConfigurationVersion !== this.settingsService.configurationVersion) {
            this.downloadConfiguration();
        }
        if (response.ServerTime && requestDuration < 2000) {
            const serverTime = new Date(response.ServerTime);
            if (Math.abs(serverTime.getTime() - timeBeforeRequest.getTime()) > this.settingsService.serverTimeOffsetLock) {
                this.appState.globalError.addFlag(ErrorState.TimeNotSynced);
            }
        }
    }

    @action async downloadConfiguration() {
        const configuration = await this.apiService.getDeviceConfiguration();
        this.settingsService.update(s => {
            const currentGateConfiguration = configuration.Gates.find(i => i.Id === s.gateId);

            s.configurationVersion = configuration.Version;
            s.gates = configuration.Gates;
            s.gateId = currentGateConfiguration?.Id;
            s.homeImages = configuration.Images;
            s.homeTitles = configuration.Titles;
            s.checkTypeId = convertToCheckType(currentGateConfiguration.CheckType);
        });
    }
}
