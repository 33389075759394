import React, { ReactElement } from "react";
import { Table as MuiTable,
    TableBody as MuiTableBody,
    TableCell as MuiTableCell,
    TableHead as MuiTableHead,
    TableRow as MuiTableRow } from "@material-ui/core";

export interface Column<T> {
    title: string | ReactElement;
    valueGetter: (item: T, index: number) => string | ReactElement;
    tableCellClassName?: (item: T) => string;
}

export interface TableProps<T> {
    columns: Column<T>[];
    data: T[];
    className?: string;
}

export const Table: <T>(props: TableProps<T>) => React.ReactElement<TableProps<T>> = (props) => {
    const { columns, data, className } = props;

    return (
        <MuiTable className={className}>
            <MuiTableHead>
                <MuiTableRow>
                    {columns.map((col, idx) =>
                        <MuiTableCell key={idx}>
                            {col.title}
                        </MuiTableCell>
                    )}
                </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody>
                {data.map((item, idx) => (
                    <MuiTableRow key={idx}>
                        {columns.map((col, cIdx) => (
                            <MuiTableCell
                                className={col.tableCellClassName ? col.tableCellClassName(item) : ""}
                                key={cIdx}
                            >
                                {col.valueGetter(item, idx)}
                            </MuiTableCell>
                        ))}
                    </MuiTableRow>
                ))}
            </MuiTableBody>
        </MuiTable>
    );
};