import React, { CSSProperties } from "react";
import { observer } from "mobx-react";
import { Layout } from "./Layout";
import { SettingsService } from "../_Services/SettingsService";
import { r } from "../_Services/LanguageService";
import { getCompressedTextSize, getDeclineReasonString } from "../_Utils/textUtils";
import { DeclineReason, GatePassDirection, ICheckResponse } from "../_Services/DataModel";
import { Card, CardHeader, CardContent, CardMedia, CardActions, Typography, Button } from "@material-ui/core";

import PersonIcon from "@material-ui/icons/Person";
import OutIcon from "@material-ui/icons/Rotate90DegreesCcw";
import InIcon from "@material-ui/icons/ExitToApp";

const cardTitleMaxWidth = 0.7; // card max width percents without padding percents

export interface ScanCardProps {
    getResponse: () => ICheckResponse;
    close?: () => void;
    headerClick?: (e: React.MouseEvent<HTMLElement>) => void;
    className?: string;
    settingsService: SettingsService;
    style?: CSSProperties;
}

@observer
export class ScanCard extends React.Component<ScanCardProps> {
    getInvalidMessage(response: ICheckResponse): string {
        if (response.Message) {
            return response.Message;
        }
        else {
            return getDeclineReasonString(response.GeneratedDeclineReason);
        }
    }

    render() {
        const { getResponse, close, className, settingsService, headerClick, style } = this.props;
        const response = getResponse();
        const isEmptyImage = response.GeneratedDeclineReason === DeclineReason.BadgeDoesNotExist ||
            response.GeneratedDeclineReason === DeclineReason.MoreValidBadges;

        return <Layout className={`scan-card-container ${className || ""}`} style={style}>
            {
                response &&
                <Card className="card">
                    <CardHeader
                        title={
                            <>
                                {
                                    !!response.IsValid &&
                                    <div className="card-title valid">
                                        {
                                            response.GatePassResponse === GatePassDirection.In &&
                                            <>
                                                <InIcon />
                                                {r("In").toUpperCase()}
                                            </>
                                        }
                                        {
                                            response.GatePassResponse === GatePassDirection.Out &&
                                            <>
                                                <OutIcon />
                                                {r("Out").toUpperCase()}
                                            </>
                                        }
                                    </div>
                                }
                                {
                                    !response.IsValid &&
                                    <Typography
                                        className="card-title invalid"
                                        style={{ fontSize: getCompressedTextSize(r(this.getInvalidMessage(response)), cardTitleMaxWidth) }}
                                    >
                                        {r(this.getInvalidMessage(response))}
                                    </Typography>
                                }
                            </>
                        }
                        subheader={
                            !response.IsValid &&
                            settingsService.isActionTextEnabled &&
                            <Typography
                                className="card-title invalid-action"
                                style={{ fontSize: getCompressedTextSize(r(response.OperationActionForDeclinationReason), cardTitleMaxWidth) }}
                            >
                                {r(response.OperationActionForDeclinationReason)}
                            </Typography>
                        }
                        onClick={e => headerClick(e)}
                        style={{ padding: 0 }}
                    />
                    <CardContent className="card-content">
                        <div className="image-container">
                            {
                                response.Images &&
                                response.Images.filter(t => t).map((i, idx) =>
                                    <CardMedia
                                        key={`image-${idx}`}
                                        className="image"
                                        component="img"
                                        image={i}
                                    />)
                            }
                            {
                                isEmptyImage &&
                                <PersonIcon className="image-icon" />
                            }
                        </div>
                        <div className="title-container">
                            <div className="layout-form">
                                {
                                    response.Titles &&
                                    response.Titles.map((i, idx) =>
                                        <Typography key={`title-${idx}`} className={`title title-${idx}`}>
                                            {i}
                                        </Typography>
                                    )
                                }
                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        {
                            !!close &&
                            <Button
                                color="inherit"
                                style={{ marginLeft: "auto" }}
                                onClick={_ => close()}
                            >
                                {r("Close")}
                            </Button>
                        }
                    </CardActions>
                </Card>
            }
        </Layout>;
    }
}