import Dexie, { } from "dexie";
import { CheckType, DbQuotaInfo, BadgeState, ErrorState, GatePassDirection, ItemsType, IBadgeData, IBadgesPackage } from "./DataModel";
import { observable } from "mobx";
import { AppState } from "../AppState";

export interface CheckData {
    TickId?: string;
    Timestamp: string;
    GateId: string;
    CheckType: CheckType;
    BadgeId: string;
    IsValid: boolean;
    IsTestOnly: boolean;
    Message: string;
    OperationActionForDeclinationReason: string;
    GatePassResponse: GatePassDirection;
    GeneratedDeclineReason: number;
    IsSync: boolean;
    Images: string[];
    Titles: string[];
}

interface CheckDataStore extends CheckData { // db store to have indexable bool
    IsSyncNumber: number;
}

interface PhotoStore {
    Id?: string;
    PhotoData: string;
}

export class AppDb extends Dexie {
    private checks: Dexie.Table<CheckDataStore, string>;
    private photos: Dexie.Table<PhotoStore, string>;
    private badges: Dexie.Table<IBadgeData, string>;
    private badgesPackages: Dexie.Table<IBadgesPackage, string>;

    constructor(private appState: AppState) {
        super("GateApp");
        this.version(1).stores({
            // be carefull this describe indexes only!!!
            checks: "TickId,Timestamp,GateId,IsSyncNumber",
            photos: "Id",
            badges: "Id,Code,GateId",
            badgesPackages: "Id,GateId"
        });
        this.estimatedQuotaUpdate();
        this.setItemsCount();

        if (navigator.storage && navigator.storage.persist) {
            navigator.storage.persisted().then((isPersistent: boolean) => {
                if (!isPersistent) {
                    navigator.storage.persist().then((persistent) => {
                        if (persistent)
                            console.log("DB is now persistent.");
                        else
                            console.log("DB can not set persist.");
                    });
                } else {
                    console.log("DB is persistent.");
                }
            });
        }
    }

    @observable dbQuotas: DbQuotaInfo = null;
    @observable dbQuotaDisplayWarning: boolean = false;
    @observable itemsToSynchronize: number | null = null;
    @observable itemsSynchronized: number | null = null;

    async getOfflineBadgesPackages(): Promise<IBadgesPackage[]> {
        return await this.badgesPackages.toArray();
    }

    async addOfflineBadgesPackage(offlineBadgesPackage: IBadgesPackage): Promise<void> {
        return await this.transaction("rw", this.badgesPackages, this.badges, async () => {
            if (offlineBadgesPackage) {
                await this.badgesPackages.add(offlineBadgesPackage);
                const badges = offlineBadgesPackage.Badges;
                if (badges?.length) {
                    await this.badges.bulkPut(badges);
                }
            }
        }).catch((err) => {
            this.checkQuotaError(err);
        }).finally(() => {
            this.estimatedQuotaUpdate();
        });
    }
    
    async deleteOfflineBadgesPackages(gateId: string): Promise<void> {
        return await this.transaction("rw", this.badgesPackages, this.badges, async () => {
            await this.badgesPackages
                .where("GateId")
                .equals(gateId)
                .delete();
            
            await this.badges
                .where("GateId")
                .equals(gateId)
                .delete();
        }).catch((err) => {
            this.checkQuotaError(err);
        }).finally(() => {
            this.estimatedQuotaUpdate();
        });
    }

    async getBadge(gateId: string, badgeId: string): Promise<IBadgeData | undefined> {
        const badge = await this.badges
            .where("Code")
            .equals(badgeId)
            .and((x) => x.GateId === gateId)
            .first();
    
        if (badge) {
            badge.ValidityIntervals?.forEach(int => {
                int.From = new Date(int.From);
                int.To = new Date(int.To);
            });
        }
        
        return badge;
    }

    async getRequiredPhotoIds(): Promise<string[]> {
        const fb = await this.badges.filter(i => i.State !== BadgeState.Deleted).toArray();
        return fb.map(i => i.PhotoId).filter((v, i, arr) => !!v && arr.indexOf(v) === i) || [];
    }

    async getItemsCount(itemsNumber: number): Promise<number> {
        return await this.checks.where("IsSyncNumber").equals(itemsNumber).count();
    }

    async getPendingItems(): Promise<CheckData[]> {
        const pendingItems = await this.checks.where("IsSyncNumber").equals(ItemsType.Pending);
        return await pendingItems.toArray();
    }

    async markSynchronized(syncedItems: CheckData[]): Promise<void> {
        if (syncedItems?.length) {
            return await this.transaction("rw", this.checks, async () => {
                await Promise.all(syncedItems.map(item => this.checks.update(item.TickId, { IsSyncNumber: 1, IsSync: true })));
            })
                .catch(err => {
                    console.error("mark synchronized - db save failed!");
                    this.checkQuotaError(err);
                }).finally(async () => {
                    this.setItemsCount();
                });
        }

    }

    async addNewCheck(check: CheckData): Promise<string> {
        const s: CheckDataStore = { IsSyncNumber: Number(check.IsSync), ...check };
        try {
            return await this.checks.add(s);
        } catch (e) {
            this.checkQuotaError(e);

        } finally {
            this.estimatedQuotaUpdate();
            this.setItemsCount();
        }
    }

    async getOrderedChecksDesc(): Promise<CheckData[]> {
        return await this.checks
            .orderBy("Timestamp")
            .reverse()
            .toArray();
    }

    async getCheckCount(syncedOnly: boolean): Promise<number> {
        if (syncedOnly) {
            return this.checks.where("IsSyncNumber").equals(1).count();
        }
        return this.checks.count();
    }

    async clearHistory(clearSyncedOnly: boolean = true): Promise<void> {
        try {
            if (clearSyncedOnly) {
                await this.checks.where("IsSyncNumber").equals(1).delete();
                return;
            }
            return await this.checks.clear();
        } finally {
            this.estimatedQuotaUpdate();
            this.setItemsCount();
        }
    }

    async clearOutOfDateHistory(): Promise<void> {
        try {
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            await this.checks.where("Timestamp").below(yesterday.toISOString()).delete();
            return;
        } finally {
            this.estimatedQuotaUpdate();
            this.setItemsCount();
        }
    }

    async clearAllPhotos(): Promise<void> {
        await this.removeObsoleteAndGetMissingIds([]);
        return;
    }

    async storePhoto(photoId: string, photoData: Blob): Promise<void> {
        const prom = new Promise<void>((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.readAsDataURL(photoData);
                reader.onabort = (e) => {
                    throw "Failed to read photo data - abort";
                };
                reader.onerror = (e) => {
                    throw "Failed to read photo data - error";
                };
                reader.onloadend = async (e) => {
                    const dataUrl: string = reader.result as string;
                    try {
                        await this.photos.put({ Id: photoId, PhotoData: dataUrl });
                    } catch (err) {
                        this.checkQuotaError(err);
                    }

                    resolve();
                    this.estimatedQuotaUpdate();
                };
            } catch (e) {
                reject(e);
            }
        });

        return prom;
    }

    async getPhotoBase64(photoId: string): Promise<string> {
        const item = await this.photos.get(photoId);
        return item.PhotoData;
    }

    async getStoredPhotoKeys(ids?: string[]): Promise<string[]> {
        const items = await (ids
            ? this.photos.where("Id").anyOf(ids)
            : this.photos);
        return (await items.toArray()).map(i => i.Id);
    }

    async removeObsoleteAndGetMissingIds(keepIds: string[]): Promise<string[]> {
        try {
            const stored = await this.getStoredPhotoKeys();
            const toDelete = stored.filter(i => keepIds.indexOf(i) === -1);
            await this.photos.bulkDelete(toDelete);

            return keepIds.filter(i => stored.indexOf(i) === -1);
        } finally {
            this.estimatedQuotaUpdate();
            this.setItemsCount();
        }
    }

    private async setItemsCount() {
        this.itemsToSynchronize = await this.getItemsCount(ItemsType.Pending);
        this.itemsSynchronized = await this.getItemsCount(ItemsType.Synchronized);
    }

    private estimatedQuotaUpdate() {
        if (navigator?.storage?.estimate) {
            navigator.storage.estimate().then(estimation => {
                const est: DbQuotaInfo = {
                    Quota: Math.floor(estimation.quota / 1000000),
                    Usage: Math.floor(estimation.usage / 10000) / 100,
                    Units: "MB",
                    Percentage: Math.floor((estimation.usage * 1000) / estimation.quota) / 100
                };
                this.dbQuotas = est;

                if (est.Percentage > 90) {
                    this.dbQuotaDisplayWarning = true;
                } else {
                    this.dbQuotaDisplayWarning = false;
                }
            });
        } else {
            console.error("StorageManager not found");
        }
    }

    private checkQuotaError(err: Dexie.DexieError): never {
        const isQuotaError = ((err.name === Dexie.errnames.QuotaExceeded) || (err.inner?.name === Dexie.errnames.QuotaExceeded));
        if (isQuotaError) {
            this.appState.globalError.addFlag(ErrorState.DbQuotaReached);
        }
        throw err;
    }
}
